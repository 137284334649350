import Logo from '../Logo'
import styles from './LogoOnlyNavbar.module.scss'

export const LogoOnlyNavbar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Logo />
      </div>
    </div>
  )
}
