const webHook =
  'https://late-glade-2372.tines.com/webhook/7bd79a22168b6e5ee350c57e64b20373/464958b53224e53fdeec92399456c6c4?actionToken='

const postActionToken = async (token: string): Promise<any> => {
  try {
    const webHookWithToken = `${webHook}${token}`
    const response = await fetch(webHookWithToken, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    return response
  } catch (err) {
    console.log(err)
    return
  }
}

/**
 * Retrieves an action token from transmit and sends it to the security team's backend.
 */
export const transmitRiskToken = (): void => {
  if (
    typeof window === 'undefined' ||
    typeof window.myTSAccountProtection === 'undefined'
  )
    return
  let postResult: any
  window.myTSAccountProtection
    .triggerActionEvent('register')
    .then((actionResponse: any) => {
      const actionToken = actionResponse.actionToken
      postResult = postActionToken(actionToken)
      console.log(
        `myTSAccountProtection transaction actionToken = ${actionToken}`
      )
    })
  return postResult
}
