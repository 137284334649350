import { FE_INCOMING_CALLS_LP } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

const phoneNumbers = {
  phoneTreatment1: {
    key: '(814) 637-3793',
    value: 'tel:+18146373793',
  },
  phoneTreatment2: {
    key: '(573) 983-3741',
    value: 'tel:+15739833741',
  },
  phoneTreatment3: {
    key: '(415) 423-1901',
    value: 'tel:+14154231901',
  },
}

export const useIncomingCallsLP = ({
  treatmentVariant,
}: {
  treatmentVariant?: string
}) => {
  const { isLoading, variation } = useDecisionForFlag({
    name: FE_INCOMING_CALLS_LP.EXPERIMENT_KEY,
    fallbackVariation: FE_INCOMING_CALLS_LP.VARIATIONS.CONTROL,
  })

  const demoIncomingCallsTreatment1 =
    treatmentVariant === 'fe_incoming_calls_lp-treatment-1'
  const demoIncomingCallsTreatment2 =
    treatmentVariant === 'fe_incoming_calls_lp-treatment-2'
  const demoIncomingCallsTreatment3 =
    treatmentVariant === 'fe_incoming_calls_lp-treatment-3'

  const isTreatment1 =
    variation === FE_INCOMING_CALLS_LP.VARIATIONS.TREATMENT_1 ||
    demoIncomingCallsTreatment1
  const isTreatment2 =
    variation === FE_INCOMING_CALLS_LP.VARIATIONS.TREATMENT_2 ||
    demoIncomingCallsTreatment2
  const isTreatment3 =
    variation === FE_INCOMING_CALLS_LP.VARIATIONS.TREATMENT_3 ||
    demoIncomingCallsTreatment3

  const getPhoneNumber = () => {
    if (isTreatment1) {
      return phoneNumbers.phoneTreatment1
    }
    if (isTreatment2) {
      return phoneNumbers.phoneTreatment2
    }
    if (isTreatment3) {
      return phoneNumbers.phoneTreatment3
    }

    return null
  }

  const phoneNumber = getPhoneNumber()

  return {
    isLoading,
    treatmentPhoneNumber: phoneNumber,
    isTreatment1,
    isTreatment2,
    isTreatment3,
  }
}
