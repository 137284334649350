/* eslint-disable @next/next/no-img-element */
import React from 'react'

import Link from 'next/link'

import { DEFAULT_FOOTER_CONTACT_INFO } from '../../global/Footer/FooterContactInfo'
import styles from './AstaNavbar.module.scss'
import Logo from './Logo'

const CONSTANTS = {
  text: 'Call us for help',
  altPhoneText: '(888) 713-8467 ',
  altPhoneLink: 'tel:+8887138467',
}

type NavbarWithPartnerProps = {
  partnerLogo: React.ReactNode
  partnerLogoMobile: React.ReactNode
  partnerLogoSeparator: 'plus' | 'pipe'
  isSofi?: boolean
}

export const NavbarWithPartner = ({
  partnerLogo,
  partnerLogoMobile,
  partnerLogoSeparator = 'plus',
  isSofi = false,
}: NavbarWithPartnerProps) => {
  const Plus = () => (
    <div className={styles.plus}>
      <img
        src="https://res.cloudinary.com/getethos/image/upload/v1691063818/pluss_aizsda.svg"
        title="Plus"
        alt="Plus"
      />
    </div>
  )
  const Pipe = () => <div className={styles.pipe}>|</div>

  const Separator = () =>
    partnerLogoSeparator === 'plus' ? <Plus /> : <Pipe />
  const mobileLogoClasses = [
    styles.partnerLogoMobileContainer,
    partnerLogoSeparator === 'pipe' ? styles.pipeLayout : null,
  ]
  const logoClasses = [
    styles.partnerLogoContainer,
    partnerLogoSeparator === 'pipe' ? styles.pipeLayout : null,
  ]

  const phoneLink = isSofi
    ? CONSTANTS.altPhoneLink
    : DEFAULT_FOOTER_CONTACT_INFO.PHONE_NUMBER.LINK
  const phoneText = isSofi
    ? CONSTANTS.altPhoneText
    : DEFAULT_FOOTER_CONTACT_INFO.PHONE_NUMBER.DISPLAY

  return (
    <>
      <div role="banner" className={styles.fixed}>
        <div className={styles.container}>
          <Link href="/">
            <Logo className={styles.logo} />
          </Link>
          {partnerLogoMobile && (
            <div className={mobileLogoClasses.join(' ')}>
              <Separator />
              <div className={styles.partnerLogoMobile}>
                {partnerLogoMobile}
              </div>
            </div>
          )}
          {partnerLogo && (
            <div className={logoClasses.join(' ')}>
              <Separator />
              <div className={styles.partnerLogo}>{partnerLogo}</div>
            </div>
          )}
          <div className={styles.phoneNumberContainer}>
            <div className={styles.Eyebrow2}>
              {isSofi ? 'CALL ETHOS FOR HELP' : CONSTANTS.text}
            </div>
            <a className={styles.phoneNumber} href={phoneLink} target="_blank">
              {phoneText}
            </a>
          </div>
        </div>
        <div className={styles.baseRoot} role="progressbar">
          <div className={styles.progressLength} />
        </div>
      </div>
    </>
  )
}
