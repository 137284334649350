import { useGlobalSettings } from '@/hooks/content/useGlobalSettings'
import { useIncomingCallsLP } from '@/hooks/features/useIncomingCallsLP'

import Logo from './Logo'
import styles from './PhoneNavbar.module.scss'

interface PhoneNavbarProps {
  settings: Record<string, any>
}

export const PhoneNavbar = (props: PhoneNavbarProps) => {
  const globalSettings = useGlobalSettings('global.md', props.settings)

  const { isTreatment1, isTreatment2, isTreatment3, treatmentPhoneNumber } =
    useIncomingCallsLP({ treatmentVariant: globalSettings.treatmentVariant })

  const getPhoneNumberValue = () => {
    if (isTreatment1 || isTreatment2 || isTreatment3) {
      return {
        key: treatmentPhoneNumber?.key,
        value: treatmentPhoneNumber?.value,
      }
    }

    return {
      key: globalSettings.phoneOnlyNavbarNumber,
      value: globalSettings.phoneOnlyNavbarNumberLink,
    }
  }

  const phoneNumber = getPhoneNumberValue()

  return (
    <>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.phoneNumberContainer}>
          <div className={styles.copy}>
            {globalSettings.phoneOnlyNavbarCopy}&nbsp;
          </div>
          <a
            className={styles.phoneNumber}
            href={phoneNumber.value}
            target="_blank"
          >
            {phoneNumber.key}
          </a>
        </div>
      </div>
    </>
  )
}
