/**
 * These file hosts various meta tags
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/meta
 * Meta tags managed by this file are as follows:*
 *  ---- General ----
 *  - og:title
 *  - description & og:description
 *  - robots
 *
 *  ---- Open Graph protocol ----
 *  - og:type
 *  - og:image / og:image:height / og:image:width / og:image:type
 *  - og:url (og canonical)
 *  - og:site_name
 *
 *  ---- Twitter sharing----
 *  - twitter:site
 *  - twitter:card
 *  - twitter:image
 *  - twitter:image:alt
 *
 *  ---- Schema.org for Google ----
 *  - itemprop="image"
 *  - itemprop="description"
 *  - itemprop="name"
 *
 * @see {@link http://ogp.me/|Open Graph protocol}
 * @see {@link https://developers.facebook.com/tools/debug/|Facebook sharing debugger }
 * @see {@link https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/markup.html|Twitter ShareDoc }
 * @see {@link https://search.google.com/structured-data/testing-tool/u/0/|Google Structured Data Test Tool}
 */

import React from 'react'

/**
 * MetaPage contains page specific meta data
 * @param {boolean} indexRobot - Tells web crawlers wether or not to index the page
 * @param {string} description - Page description for meta tag
 * @param {string} title - Concatenated: [unique page title] - [global site title].
 * @param {string} url - Site url.
 */
export function MetaPage({
  description,
  title,
  url,
}: {
  description: string
  title: string
  url: string
}) {
  return (
    <>
      <meta property="og:site_name" content="Ethos Life" />
      <meta name="robots" content="index, follow" />
      <meta name="description" content={description} />
      {/* Open graph protocol */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@getEthoslife" />
      <meta name="twitter:description" content={description} />
      {/* Schema.org */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
    </>
  )
}

/**
 * MetaImage contains social/og/schema images
 * @param {string} imageUrl - og sharing image
 * @param {string} imageAlt - sharing image alt text
 * @param {string} twitterImageUrl - twitter sharing image
 *
 */
export function MetaImage({
  imageUrl,
  imageAlt,
  twitterImageUrl,
}: {
  imageUrl: string
  imageAlt: string
  twitterImageUrl: string
}) {
  return (
    <>
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:alt" content={imageAlt} />
      <meta property="og:image:width" content="1200" />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={twitterImageUrl} />
      <meta name="twitter:image:alt" content={imageAlt} />
      {/* Schema.org */}
      <meta itemProp="image" content={imageUrl} />
    </>
  )
}
