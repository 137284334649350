import React from 'react'

import PropTypes from 'prop-types'

export const UrlContext = React.createContext('url')

const FootnoteFooterContext = React.createContext({
  displayFootnoteFooter: false,
  bodyText: 'Example text',
})

/******************************
 * Footnote footer provider+context
 *****************************/

export const useFootnoteFooterContext = () => {
  const context = React.useContext(FootnoteFooterContext)
  if (!context)
    throw new Error(
      'useFootnoteFooterContext must be used within a FootnoteFooterProvider'
    )
  return context
}

export const FootnoteFooterProvider = ({ footnoteFooterInfo, ...rest }) => {
  // On first load footnoteFooterInfo is specifically defined as null,
  // causing bugs if you don't destructure it
  const bodyText = footnoteFooterInfo ? footnoteFooterInfo.bodyText : ''
  const displayFootnoteFooter =
    footnoteFooterInfo && footnoteFooterInfo.displayFootnoteFooter
  // ...rest is required to pass down children and etc
  return (
    <FootnoteFooterContext.Provider
      value={{
        bodyText,
        displayFootnoteFooter,
      }}
      {...rest}
    />
  )
}

FootnoteFooterProvider.propTypes = {
  footnoteFooterInfo: PropTypes.shape({
    bodyText: PropTypes.string,
    displayFootnoteFooter: PropTypes.bool,
  }),
}
