import React from 'react'

interface FaqQuestion {
  question: string
  answer: string
}

interface StructuredFaqProps {
  questions: Array<FaqQuestion>
}

export const StructuredFaq = ({ questions }: StructuredFaqProps) => {
  const generateScript = () => {
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: questions.map((faq) => ({
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.answer,
        },
      })),
    }

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
    )
  }
  const scriptTag = generateScript()

  return <>{scriptTag}</>
}
