import { QUESTION_TEMPLATE } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useQuestionTemplate = () => {
  const { isLoading, variation } = useDecisionForFlag({
    name: QUESTION_TEMPLATE.EXPERIMENT_KEY,
    fallbackVariation: QUESTION_TEMPLATE.VARIATIONS.CONTROL,
  })

  return {
    isLoading,
    isTreatment: variation === QUESTION_TEMPLATE.VARIATIONS.TREATMENT,
  }
}
