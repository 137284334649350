import React from 'react'

interface AddressProps {
  streetAddress: string
  addressLocality: string
  addressRegion: string
  postalCode: string
  addressCountry: string
}

interface OrganizationProps {
  organizationStructure: {
    name: string
    url: string
    logo: string
    description: string
    address: AddressProps
    founder: Array<{ name: string }>
  }
  enableOrganization: boolean
}

export const StructuredOrganization = ({
  organizationStructure: { name, url, logo, description, founder, address },
  enableOrganization,
}: OrganizationProps) => {
  const generateScript = () => {
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name,
      url,
      logo,
      description,
      address: {
        '@type': 'PostalAddress',
        streetAddress: address.streetAddress,
        addressLocality: address.addressLocality,
        addressRegion: address.addressRegion,
        postalCode: address.postalCode,
        addressCountry: address.addressCountry,
      },
      founder: founder?.map((f) => ({
        '@type': 'Person',
        name: f.name,
      })),
    }

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
    )
  }
  if (!enableOrganization) return <></>

  const scriptTag = generateScript()

  return <>{scriptTag}</>
}
