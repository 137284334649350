import Link from 'next/link'

import { DEFAULT_FOOTER_CONTACT_INFO } from '../../global/Footer/FooterContactInfo'
import styles from './AstaNavbar.module.scss'
import Logo from './Logo'

const CONSTANTS = {
  text: 'Call us for help',
}

export const AstaNavbar = () => {
  return (
    <>
      <div role="banner" className={styles.fixed}>
        <div className={styles.container}>
          <Link href="/">
            <Logo />
          </Link>
          <div className={styles.phoneNumberContainer}>
            <div className={styles.Eyebrow2}>{CONSTANTS.text}</div>
            <a
              className={styles.phoneNumber}
              href={DEFAULT_FOOTER_CONTACT_INFO.PHONE_NUMBER.LINK}
              target="_blank"
            >
              {DEFAULT_FOOTER_CONTACT_INFO.PHONE_NUMBER.DISPLAY}
            </a>
          </div>
        </div>
        <div className={styles.baseRoot} role="progressbar">
          <div className={styles.progressLength} />
        </div>
      </div>
    </>
  )
}
