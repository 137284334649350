import {
  SINGLE_CTA_EXPERIMENT,
  SINGLE_CTA_EXPERIMENT_WO_CONTROL,
} from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useSingleCTAExperiment = () => {
  const { TREATMENT_1, TREATMENT_2, CONTROL } = SINGLE_CTA_EXPERIMENT.VARIATIONS
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: SINGLE_CTA_EXPERIMENT.EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  const {
    isEnabled: isEnabledWOControl,
    isLoading: isLoadingWOControl,
    variation: variationWOControl,
  } = useDecisionForFlag({
    name: SINGLE_CTA_EXPERIMENT_WO_CONTROL.EXPERIMENT_KEY,
    fallbackVariation: TREATMENT_1,
  })

  const TEXT_CONSTANT = {
    ctaLabel: 'Start applying',
  }

  const isEnabledCheck = isEnabledWOControl || isEnabled
  const isTreatment1 =
    isEnabledCheck &&
    (variationWOControl === TREATMENT_1 || variation === TREATMENT_1)
  const isTreatment2 =
    isEnabledCheck &&
    (variationWOControl === TREATMENT_2 || variation === TREATMENT_2)

  return {
    isLoading: isLoadingWOControl || isLoading,
    isTreatment: isEnabledCheck && (isTreatment1 || isTreatment2),
    isTreatment1,
    isTreatment2,
    textConstant: TEXT_CONSTANT,
  }
}
