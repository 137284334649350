import React from 'react'

import styles from './Logo.module.scss'

const letterOClass = [styles.letter]

const EthosLogoE = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 971.997 180.498"
    className={styles.letter}
  >
    <title>ethos-logo-E</title>
    <path
      d="M0 2.845v175.23h129.318v-29.208H29.202v-43.806h86.988V75.859H29.202V32.047h100.116V2.845H0z"
      fill="#054742"
    />
  </svg>
)

const EthosLogoT = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 971.997 180.498"
    className={styles.letter}
  >
    <title>ethos-logo-T</title>
    <path
      d="M184.573 2.845v29.202h58.416v146.028h29.202V32.047h58.41V2.845H184.573z"
      fill="#054742"
    />
  </svg>
)

const EthosLogoH = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 971.997 180.498"
    className={styles.letter}
  >
    <title>ethos-logo-H</title>
    <path
      d="M505.833 2.845v73.014h-87.612V2.845h-29.208v175.23h29.208v-73.014h87.612v73.014h29.202V2.845h-29.202z"
      fill="#054742"
    />
  </svg>
)

const EthosLogoO = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 971.997 180.498"
    className={styles.letter}
  >
    <title>ethos-logo-O</title>
    <path
      d="M758.521 19.368a63.8 63.8 0 0 0-90.227 0c-3.6 3.606-11.1 12.036-13.662 16.188a63.725 63.725 0 0 1 87.894 87.378l.984-.984c4.272-2.592 11.322-8.664 15.012-12.354a63.8 63.8 0 0 0 0-90.227"
      fill="#054742"
    />
    <path
      d="M650.356 127.535c-25.068-25.068-40.422-71.178-12.132-119.922L625 20.837a93.374 93.374 0 1 0 132.054 132.048l13.224-13.224c-46.422 26.988-92.616 15.18-119.922-12.126"
      fill="#054742"
    />
  </svg>
)

const EthosLogoS = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 971.997 180.498"
    className={styles.letter}
  >
    <title>ethos-logo-S</title>
    <path
      d="M902.169 180.71c42.42 0 69.828-21.018 69.828-53.55 0-30.5-21.438-41.532-56.034-49.428l-21.192-4.65c-19.08-4.218-29.424-9.12-29.424-22.944 0-14.094 13.134-23.2 33.462-23.2 18.966 0 35.658 7.044 45.942 19.362l22.008-17.69C954.645 15.51 933.273.21 899.583.21c-38.064 0-65.694 21.648-65.694 51.48 0 34.044 27.87 44.436 52.152 49.686l23.52 5.172c18.594 4.038 30.978 9.576 30.978 23.454 0 14.916-13.422 23.46-36.816 23.46-25.518 0-44.808-12.534-52.89-25.17l-24.15 17.388c12.438 17.046 38.64 35.028 75.486 35.028"
      fill="#054742"
    />
  </svg>
)

const Logo = () => {
  return (
    <div role="img" aria-label="Ethos" className={styles.logo}>
      <EthosLogoE />
      <EthosLogoT />
      <EthosLogoH />
      <EthosLogoO />
      <EthosLogoS />
    </div>
  )
}

export default Logo
