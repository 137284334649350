import { useEffect, useMemo, useState } from 'react'

import { PHASE_PRODUCTION_BUILD } from 'next/dist/shared/lib/constants'

import { useDecision } from '@optimizely/react-sdk'

import { optimizelyInstance } from './optimizelyInstance'

const DEFAULT_VALUES = {
  isEnabled: false,
  variables: {},
  isLoading: true,
}

interface Feature {
  name: string
  fallbackVariation: string
  bypassActivate?: boolean
}
const useOptimizelyDecision = ({ name, fallbackVariation }: Feature) => {
  const [isLoading, setLoading] = useState(true)
  const [decision, clientReady] = useDecision(name)

  const instance = optimizelyInstance()

  useEffect(() => {
    if (!instance) return
    instance.onReady().finally(() => setLoading(false))
  }, [clientReady, instance])

  return useMemo(
    () => ({
      isEnabled: decision?.enabled,
      variation: decision?.variationKey || fallbackVariation,
      variables: decision?.variables,
      isLoading,
    }),
    [
      decision?.enabled,
      decision?.variables,
      decision?.variationKey,
      fallbackVariation,
      isLoading,
    ]
  )
}

export const useDecisionForFlag = ({
  name,
  bypassActivate,
  fallbackVariation,
}: Feature) => {
  const getDecision = useOptimizelyDecision

  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return {
      ...DEFAULT_VALUES,
      isLoading: true,
      variation: fallbackVariation,
    }
  }

  if (bypassActivate) {
    return {
      ...DEFAULT_VALUES,
      variation: fallbackVariation,
    }
  }

  return getDecision({ name, fallbackVariation })
}
