interface GlobalSettings {
  [key: string]: any
}

export const useGlobalSettings = (
  keys: string | string[],
  settings: Record<string, any>
) => {
  const globalSettings: GlobalSettings = settings || {}
  if (Array.isArray(keys)) {
    return keys.map((key) => globalSettings[key])
  }
  return globalSettings[keys]
}
