import React from 'react'

import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
//@ts-ignore
import htmlParser from 'react-markdown/plugins/html-parser'

import uriTransformerCustomized from './UriTransformerCustomized'

// Subscript, superscript
const allowedHtmlTags = ['sup', 'sub']

// https://github.com/rexxars/react-markdown#node-types
export const MARKDOWN_NODE_RESTRICTION_SETS = {
  BASIC_TEXT_WITH_LINKS: [
    // Basic text nodes
    'root',
    'text',
    'break',
    'paragraph',
    'link',
    'linkReference',
    'list',
    'listItem',
    'strong',
  ],
  LINK_ONLY: [
    // use this for widgets that are string/text type and wrapped by a single
    // a self contained EDS component, to prevent extra <p> tag wrapping
    'link',
  ],
  NEW_TAB_LINKS: [
    // use this for widgets whose links should all open in a new tab
    'root',
    'text',
    'break',
    'paragraph',
    'link',
    'linkReference',
    'list',
    'listItem',
    'strong',
  ],
}

// This basically sanitizes out all html except for 'sup' and 'sub' elements.
// You can see examples in the Highlight module in demo/index.md.
// https://github.com/rexxars/react-markdown#parsing-html
const parseHtml = htmlParser({
  isValidNode: () => true,
  processingInstructions: [
    {
      shouldProcessNode: function (node: { name: { toString: () => string } }) {
        return allowedHtmlTags.indexOf(node.name.toString()) >= 0
      },
      processNode: function (
        node: { name: string },
        children:
          | string
          | number
          | boolean
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined,
        index: any
      ) {
        return React.createElement(node.name, { key: index }, children)
      },
    },
  ],
})

const isExternalLink = (url: string) => {
  // Check if the URL starts with "http://", "https://", or "//" (protocol-relative URL)
  return (
    (/^https?:\/\//i.test(url) || url.startsWith('//')) &&
    !url.includes('ethoslife.com')
  )
}

const linkRenderer = ({ href, children }: { href: string; children: any }) => {
  // Check if the link is external
  if (isExternalLink(href)) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  } else {
    return <a href={href}>{children}</a>
  }
}

// super basic at the moment, could probably use some sanitization and
// validation to ensure valid and safe markup is passed as the input

type MarkdownProps = {
  input: string | any
  renderers?: object
  allowedMarkdownTypes?: typeof MARKDOWN_NODE_RESTRICTION_SETS | null | string[]
  className?: string | null
  disallowedTypes?: string[]
}

type MarkdownPassedProps = {
  linkTarget: string
  escapeHtml: boolean
  source: string
  renderers: object
  astPlugins: any[]
  className: string
  transformLinkUri: (uri: string) => string
  disallowedTypes?: string[]
  unwrapDisallowed?: boolean
  allowedTypes?: string[] | undefined
}

const Markdown = ({
  input,
  renderers = {},
  allowedMarkdownTypes = null,
  className = '',
}: MarkdownProps): JSX.Element => {
  const defaultRenderers = {
    link: linkRenderer,
  }

  const passedProps = {
    escapeHtml: false,
    source: typeof input === 'string' ? input : '',
    renderers: { ...defaultRenderers, ...renderers },
    astPlugins: [parseHtml],
    className: className,
    transformLinkUri: uriTransformerCustomized,
  } as MarkdownPassedProps

  // strip extra <p> tag if we're only rendering a link, since the markdown widget
  // output in the markup should already be wrapped in an EDS Text component
  // funky workaround because you can't specify disallowedTypes & allowedTypes together
  if (allowedMarkdownTypes != null) {
    if (allowedMarkdownTypes === MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY) {
      passedProps.disallowedTypes = ['paragraph']
      passedProps.unwrapDisallowed = true
    } else {
      passedProps.allowedTypes = allowedMarkdownTypes as string[]
    }
    //Open links in new tabs
    if (allowedMarkdownTypes === MARKDOWN_NODE_RESTRICTION_SETS.NEW_TAB_LINKS) {
      passedProps.linkTarget = '_blank'
    }
  }
  //@ts-ignore
  return <ReactMarkdown {...passedProps} />
}

Markdown.propTypes = {
  className: PropTypes.string,
  input: PropTypes.string.isRequired,
  renderers: PropTypes.object,
  allowedMarkdownTypes: PropTypes.oneOf(
    Object.values(MARKDOWN_NODE_RESTRICTION_SETS)
  ),
}

export default Markdown
