import React from 'react'

import showdown from 'showdown'

import { Article } from './StructuredArticle'
import { StructuredFaq } from './StructuredFaq'
import { StructuredOrganization } from './StructuredOrganization'

const structureDataModules = {
  articleStructure: Article,
  faqStructure: StructuredFaq,
  organization: StructuredOrganization,
}

const prepareFaqStructureData = (data: Array<any>) => {
  const converter = new showdown.Converter()
  const formattedData = data.map((edge) => {
    const {
      faqSingle: { question, answer },
    } = edge
    return { question, answer: converter.makeHtml(answer) }
  })
  const structuredData = {
    questions: formattedData,
    type: 'faqStructure',
  }
  return structuredData
}

export const StructuredData = ({ structuredData = [], ...rest }: any) => {
  const isFaqIndex = rest?.templateKey === 'faq-index-template'
  const modifiedStructuredData = isFaqIndex
    ? [prepareFaqStructureData(rest.faqSingles), ...structuredData]
    : structuredData
  if (!modifiedStructuredData) return null
  return (
    <>
      {modifiedStructuredData.map((item: any, index: number) => {
        const Type =
          structureDataModules[item.type as keyof typeof structureDataModules]
        if (!Type) return null
        return <Type key={`${index}-${item.type}`} {...item} {...rest} />
      })}
    </>
  )
}
