import React from 'react'

import { Footnote, Spacer } from 'ethos-design-system'

import styles from './Footer.module.scss'

export const DEFAULT_FOOTER_CONTACT_INFO = {
  STREET_ADDRESS: ['1606 Headway Circle', '#9013', 'Austin, TX 78754'],
  PHONE_NUMBER: {
    DISPLAY: '(415) 915-0665',
    LINK: 'tel:+14159150665',
  },
  PARTNER_PHONE_NUMBER: {
    DISPLAY: '(415) 639-3968',
    LINK: 'tel:+14156393968',
  },
  EMAIL_LINK: 'mailto:support@ethoslife.com',
}

interface FooterContactInfoProps {
  emailLink?: string
  phoneLink?: string
  phoneNumber?: string
  displayEmail?: boolean
}

export const FooterContactInfo: React.FC<FooterContactInfoProps> = ({
  displayEmail,
  emailLink = DEFAULT_FOOTER_CONTACT_INFO.EMAIL_LINK,
  phoneLink = DEFAULT_FOOTER_CONTACT_INFO.PHONE_NUMBER.LINK,
  phoneNumber = DEFAULT_FOOTER_CONTACT_INFO.PHONE_NUMBER.DISPLAY,
}) => {
  const streetAddress = DEFAULT_FOOTER_CONTACT_INFO.STREET_ADDRESS
  return (
    <div>
      <Footnote.Medium500 element="h4">Contact Us</Footnote.Medium500>
      <Spacer.H16 />
      {[...streetAddress].map((e) => (
        <Footnote.Regular400 key={e}>{e}</Footnote.Regular400>
      ))}
      {phoneNumber && (
        <a href={phoneLink} className={styles.telephone}>
          <Footnote.Regular400>{phoneNumber}</Footnote.Regular400>
        </a>
      )}
      <div className={styles.buttons}>
        {displayEmail && (
          <a className={styles.email} href={emailLink}>
            Email us
          </a>
        )}
      </div>
    </div>
  )
}
