import React from 'react'

interface AuthorsProps {
  type: string
  name: string
  url: string
}

interface StructuredArticleProps {
  dataType: string
  headline: string
  image: Array<{ url: string }>
  datePublished: string
  dateModified: string
  author: Array<AuthorsProps>
  ogImage: string
  pageImage?: string
}

export const Article = ({
  dataType,
  headline,
  ogImage,
  datePublished,
  dateModified,
  author,
  pageImage,
}: StructuredArticleProps) => {
  const imagesArray = [ogImage]
  if (pageImage) {
    imagesArray.push(pageImage)
  }
  const generateScript = () => {
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': dataType,
      headline,
      image: imagesArray,
      datePublished,
      dateModified,
      author: author.map((author) => ({
        '@type': author.type,
        name: author.name,
        url: author.url,
      })),
    }

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
    )
  }
  if (!headline || !datePublished) return <></>
  const scriptTag = generateScript()

  return <>{scriptTag}</>
}
