import React from 'react'

const TRANSCEND_CONSENT_MANAGER_BUNDLE_ID =
  'ff6ae583-686d-4b01-998c-1b38edaa1b6c'
const TRANSCEND_DIRECTORY =
  process.env.NEXT_PUBLIC_ETHOS_ENV === 'production' ? 'cm' : 'cm-test'
const TRANSCEND_CONSENT_MANAGER_SCRIPT_SOURCE = `https://cdn.transcend.io/${TRANSCEND_DIRECTORY}/${TRANSCEND_CONSENT_MANAGER_BUNDLE_ID}/airgap.js`

/**
 * CmsConsentManager injects Transend's Airgap script in the head
 */
export const CmsConsentManager = () => {
  const enable_privacy = process.env.NEXT_PUBLIC_ENABLE_PRIVACY === '1'
  return enable_privacy ? (
    <script
      data-cfasync="false"
      data-ui="off"
      async
      src={TRANSCEND_CONSENT_MANAGER_SCRIPT_SOURCE}
      crossOrigin="anonymous"
    />
  ) : null
}
